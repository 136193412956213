// @flow
import type { SizeType } from 'antd';
import { Form, Input, Spin } from 'antd';
import lodash from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import styles from './index.module.less';

type Props = {
  className: string,
  name: string,
  label?: string,
  loading?: boolean,
  wrapperCol?: number,
  labelCol?: number,
  size?: SizeType,
  prefix?: Node,
  placeholder?: string,
  width?: number | string,
  disabled?: boolean,
  value: string,
  visibilityToggle: boolean,
  errors: Object,
  style?: Object,
  onChange: Function,
  onBlur: Function,
};

const WrappedPassword = ({
  className,
  name,
  label,
  loading = false,
  wrapperCol = 18,
  labelCol = 6,
  size,
  prefix,
  placeholder,
  width = 180,
  disabled,
  value,
  visibilityToggle = false,
  errors,
  style,
  onChange,
  onBlur,
}: Props) => {
  const [UIValue, setUIValue] = useState(value);
  const [error, setError] = useState();

  const debounced = useDebouncedCallback((val) => {
    onChange(val);
  }, 100);

  const handleChange = useCallback(
    (e) => {
      const { value: newVal } = e.target;
      setUIValue(newVal);
      debounced.callback(newVal);
    },
    [setUIValue, debounced]
  );

  // [G: 050820] Due to debounce, if user onBlurs faster then UIValue updates, validation is missed onBlur.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorMsgObj = lodash.get(errors, name);
    const errorMsg = errorMsgObj?.message;
    if (errorMsg) setError(errorMsgObj);
    if (!errorMsg) setError();
  });

  useEffect(() => {
    if (loading) return;
    setUIValue(value);
  }, [loading, value]);

  return (
    <Form.Item
      className={`${className} ${styles.Input}`}
      wrapperCol={{ span: wrapperCol }}
      labelCol={{ span: labelCol }}
      label={label}
      help={error?.message}
      style={{ ...style, width }}
      validateStatus={error?.message ? 'error' : null}
    >
      <Spin style={width ? { width } : { width: 180 }} spinning={loading}>
        <Input.Password
          type="password"
          visibilityToggle={visibilityToggle}
          name={name}
          size={size}
          prefix={prefix}
          value={UIValue}
          placeholder={placeholder}
          autoComplete="off"
          style={{ width }}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </Spin>
    </Form.Item>
  );
};

export default WrappedPassword;
