// @flow
import './index.less';

import { FacebookFilled } from '@ant-design/icons';

import { Button } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { useAuth, useAuthState } from '_contexts/auth';

type Props = {
  type?: 'signin' | 'signup',
  disabled?: boolean,
};

const FacebookButton = ({ type = 'signin', disabled }: Props): React$Node => {
  const { t } = useTranslation();

  const { loading } = useAuthState();
  const { signInWithFacebook } = useAuth();

  return (
    <Button
      className="facebook-btn"
      icon={<FacebookFilled className="facebook-icon" />}
      block
      loading={loading}
      onClick={signInWithFacebook}
      disabled={disabled}
    >
      {type === 'signup' ? t('SignUpSection_SignUpWithFacebook') : t('SignInSection_SignInWithFacebook')}
    </Button>
  );
};

export default FacebookButton;
