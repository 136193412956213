// @flow
import './index.less';

import { LockOutlined, MailFilled, MailOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { Alert, Button, Divider } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Markdown from 'react-markdown';
import * as yup from 'yup';

import FacebookButton from '_components/FacebookButton';
import WrappedInput from '_components/WrappedInputs/input';
import WrappedPassword from '_components/WrappedInputs/password';
import { useAuth } from '_contexts/auth';

const validationSchema = yup.object().shape({
  email: yup.string().email(),
  password: yup.string().required(),
});

type Props = {
  disabled?: boolean,
};

const SignUpForm = ({ disabled = false }: Props): React$Node => {
  const { t } = useTranslation();

  const { signUp } = useAuth();

  const [signUpError, setSignUpError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signUpWithEmailExpanded, setSignUpWithEmailExpanded] = useState(false);

  const { control, errors: formErrors, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    (values) => {
      (async () => {
        setLoading(true);
        try {
          await signUp(values.email.toLowerCase(), values.password);
          setSignUpError(null);
        } catch (err) {
          setSignUpError(err);
        }
        setLoading(false);
      })();
    },
    [signUp]
  );

  return (
    <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="signup-container">
        <FacebookButton type="signup" disabled={disabled} />

        <Divider className="divider" plain>
          {t('Common_Or')}
        </Divider>

        {signUpWithEmailExpanded ? (
          <>
            <div className="subtitle">{t('SignUpSection_SignUpWithEmail')}</div>
            <Controller
              as={WrappedInput}
              name="email"
              defaultValue={null}
              control={control}
              errors={formErrors}
              width="100%"
              placeholder={t('Placeholder_Email')}
              labelCol={24}
              wrapperCol={24}
              visibilityToggle
              prefix={<MailOutlined className="input-icon" />}
              size="large"
            />

            <Controller
              as={WrappedPassword}
              name="password"
              defaultValue={null}
              control={control}
              errors={formErrors}
              width="100%"
              placeholder={t('Placeholder_Password')}
              labelCol={24}
              wrapperCol={24}
              visibilityToggle
              prefix={<LockOutlined className="input-icon" />}
              size="large"
              style={{ marginBottom: 40 }}
            />
            <Button className="signup-btn" type="primary" htmlType="submit" loading={loading} block>
              {t('Common_CreateAccount')}
            </Button>
            <Markdown className="footnote">{t('SignUpSection_Footnote')}</Markdown>

            {signUpError && (
              <Alert
                message={signUpError?.message}
                description={t('Error_SignUp')}
                type="error"
                style={{ textAlign: 'left', marginTop: '1rem', color: '#b00015' }}
              />
            )}
          </>
        ) : (
          <Button
            className="signup-btn"
            icon={<MailFilled className="signup-icon" />}
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            onClick={() => setSignUpWithEmailExpanded(true)}
            disabled={disabled}
          >
            {t('SignUpSection_SignUpWithEmail')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default SignUpForm;
